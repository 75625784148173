<template>
    <b-card no-body>

        <v-table    ref="table"
                    :add-callback="createNew"
                    :items-provider="provider"
                    :fields="fields"
                    :buttons="buttons"
                    search
                    pagination="server"></v-table>
    </b-card>
</template>

<script>
import { BCard,BFormCheckbox } from 'bootstrap-vue'
import { AgreementApi } from '@/api/agreement'
export default {
    components:{
        BCard,
        BFormCheckbox
    },
    data(){
        return{
            editing:false,
            loading:false,
            fields:[
                {name:'Sözleşme No',key:'AgreementCode',sortable:true, thClass:'w-15'},
                {name:'Sözleşme Adı',key:'Name',sortable:true, thClass:'w-25'},
                {name:'Cari Kod',key:'CariCode',sortable:true, thClass:'w-15'},
                {name:'Firma Adı',key:'CariName',sortable:true, thClass:'w-25'},
                {name:'Sözleşme Baş. Tarihi',key:'StartDate',sortable:true, thClass:'w-10'},
                {name:'Sözleşme Bitiş Tarihi',key:'ExpireDate',sortable:true, thClass:'w-10'},
            ],
            buttons:[
                { text:"Detay Görüntüle",key:"view",icon:"EyeIcon",variant:"secondary",action:this.detail },
                { text:"Oransal Fiyat artışı",key:"price-history",icon:"ActivityIcon",variant:"primary",action:this.priceHistory },
                { text:"Sil",key:"delete",icon:"TrashIcon",variant:"danger",action:this.delete },
            ],
            firm:{
                Id:null,
                Name: null,
                AgreementCode:null,
                FirmId:null,
                VehicleTypeId:null,
                AgreementRoutePrices:[]
            }
        }
    },
    methods:{
        provider(data){
            return AgreementApi.Table(data)
        },
        createNew(){
            this.$router.push({name:'agreement-create'})
        },
        detail(item){
            this.$router.push({name:'agreement-detail',params:{id:item.Id}})
        },
        priceHistory(item){
            this.$router.push({name:'agreement-price-history',params:{id:item.Id}})
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.FirmName} firmasıyla olan ${item.Name} adlı sözleşmeyi silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    AgreementApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.$refs.table.refresh()
                        }
                        this.$result(res)
                        
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>